
























































import { Vue, Component } from "vue-property-decorator";

@Component
export default class Login extends Vue {
  email = "";
  password = "";

  get logInError() {
    return this.$store.state.logInError;
  }

  submit() {
    this.$store.dispatch("logIn", {
      username: this.email,
      password: this.password
    });
  }
}
